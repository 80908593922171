import React from "react";
import configData from "../../config.json";

function NoticiaCard(props) {
    const cmsURL = configData.CMS_URL;
    let locale = 'es';
    if (props.lang === 'en') {
        locale = 'en';
    }
    let noticia = null;
    if (props.data && props.data) {
        noticia = props.data;
    }
    return (
        <div key={noticia.Id}
             className="flex lg:w-2/3 mx-auto p-5 shadow mb-5 newsCard"
             onClick={() => {
                 if (!props.linkDisabled) {
                     return window.location = "/noticia/" + noticia.Id;
                 }
             }}>
            <div className="w-1/3">
                {
                    noticia.Image &&
                    <img
                        width={100}
                        className="block"
                        src={cmsURL + noticia.Image}
                        alt={noticia.Title[locale]}
                    />
                }
            </div>
            <div className="w-2/3 text-left">
                <div className="font-bold text-lg">
                    <div className="newsTitle" style={{color: props.mainColor || 'black'}}>
                        {noticia.Title[locale] && noticia.Title[locale].substring(0, 50)}</div>
                </div>
                <div className="newsText" style={{color: props.mainColor || 'black'}}>
                    {noticia.Description[locale] && noticia.Description[locale].substring(0, 100)}</div>
            </div>
        </div>
    );
}

export default NoticiaCard;
