import ReactTooltip from 'react-tooltip';
import LineCircle from "./lineCircle";

const overflowStyle = {
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'nowrap'
};

const ParadaRow = props => {
    let parada = props.data;
    let primaryColor = props.primaryColor;
    let textos = props.textos;
    let isBefore = props.isBefore;
    let parseTime = props.parseTime;
    let busLines = props.busLines;
    let linea = props.linea;
    let url =
        "https://www.google.com/maps/search/?api=1&query=" +
        parada.Latitude +
        "%2C" +
        parada.Longitude;

    let renderParadaTimeTable = function (index, time, timeActivated) {
        let isActivated = time === timeActivated;
        return <span key={index + '-md'} style={{
            marginRight: 10,
            color: isBefore(time) ? primaryColor : 'gray',
            fontSize: 12,
            padding: 1,
            maxHeight: 25,
            borderWidth: isActivated && isBefore(time) ? 1: 0
        }}>
            {parseTime(time)}
        </span>
    }
    let findFirstActiveTime = function() {
        if (isTimesSingleLevel()) {
            for (let i=0 ; i<parada.Times.length ; i++) {
                if (isBefore(parada.Times[i])) {
                    return parada.Times[i];
                }
            }
        }
    }
    let isTimesMultiLevel = function () {
        return typeof parada.Times[0] === 'object';
    }
    let isTimesSingleLevel = function () {
        return !isTimesMultiLevel();
    }
    let getBusLineById = function (id) {
        const result =  busLines.find(
            (line) => line.Id === parseInt(id, 10)
        );
        if (result) {
            return <div key={result.Id} style={{marginTop: 10}}>
                <span style={{float:'left', marginRight: 5}}>
                    <LineCircle color={result.Color} code={result.Code}/>
                </span>
                <span data-tip={result.Name} style={Object.assign({}, overflowStyle, {display: 'block'})}>{result.Name}</span>
            </div>;
        }
    }

    let isTimeActivated = findFirstActiveTime();

    return <>
    <ReactTooltip place="top" type="dark" effect="float"/>
    <div
        key={parada.Id}
        className="flex justify-center space-x-4 text-left md:text-center
                   text-sm md:text-base text-blue p-2 border-b">
        <div className="w-1/6 md:w-1/12 text-center">{parada.Code}</div>
        <div className="w-5/6 md:w-2/12 text-left">
        {parada.Name}

            <span className={'md:hidden ml-2'} style={{float: 'right'}}>
                <a href={url} target="_blank" rel="noreferrer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 mx-auto"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>
                </a>
            </span>

            <div className={'md:hidden flex-col'}
                 style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <div className={'mt-3 mb-1'}>{textos.Times_table}</div>
                <div className={'flex flex-wrap'}>
                    {
                        isTimesSingleLevel() ?
                            parada.Times.map((t, index) => {
                                return renderParadaTimeTable(index + '-xs', t, isTimeActivated)
                            })
                            :
                            <>
                                {
                                    parada.Times.map((paradaLine, indexA) => {
                                        return paradaLine.Times && paradaLine.Times.length>0 &&
                                        <div key={indexA}>
                                            { getBusLineById(paradaLine.lineId) }
                                            <div className={'flex'}
                                                 style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                                                {
                                                    paradaLine.Times.map((t, indexB) => {
                                                        return renderParadaTimeTable(indexA + indexB + '-md', t, isTimeActivated)
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </>
                    }
                </div>
            </div>

            <div className={'md:hidden flex-col'}
                 style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <div className={'mt-3 mb-1'}>{textos.Next_arrivals}</div>
                <div className={'flex flex-wrap'}>
                    {
                        parada.Panel &&
                        parada.Panel.map((t, index) => {
                            return (!!linea ? linea.Code===t.Code : true) ? <div key={index + '-xs'} 
                            style={{
                                ...overflowStyle,
                                marginLeft: 5, 
                                //width: '63%', 
                                display: 'block', 
                                float:'left',
                                width: '100%',
                                fontSize: 12,
                                color: 'gray',
                                marginBottom: 10,
                            }}>
                                <span style={{float:'left', marginRight: 5}}>
                                    <LineCircle color={t.Color} code={t.Code}/>
                                </span>
                                <span style={Object.assign({}, overflowStyle, {width: '70%', display: 'inline-block', position: 'relative', top: 5})}>{t.Name}</span>
                                <span style={{marginLeft: 3,  color: primaryColor}}>{t.Time}"</span>
                            </div>
                                :
                                <div key={index}></div>
                        })
                    }
                </div>
            </div>
        </div>

        <div className="md:w-4/12 text-left hidden md:flex"
             style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {
                isTimesSingleLevel() ?
                    parada.Times.map((t, index) => {
                        return renderParadaTimeTable(index + '-md', t, isTimeActivated)
                    })
                    :
                    <div className={'md:block'}>
                        {
                            parada.Times.map((paradaLine, indexA) => {
                                return paradaLine.Times && paradaLine.Times.length>0 &&
                                <div key={indexA}>
                                    { getBusLineById(paradaLine.lineId) }
                                    <div className={'flex'}
                                         style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                                        {
                                            paradaLine.Times.map((t, indexB) => {
                                                return renderParadaTimeTable(indexA + indexB + '-md', t, isTimeActivated)
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
            }
        </div>
        <div className="md:w-4/12 text-left hidden md:block"
             style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {
                parada.Panel &&
                parada.Panel.map((t, index) => {
                    return (!!linea ? linea.Code===t.Code : true) && t.Time ?
                    <div key={index} style={{
                        //fontSize: 12,
                        color: 'gray',
                        marginBottom: 10,
                    }}>
                        <span style={{float:'left', marginRight: 5}}>
                            <LineCircle color={t.Color} code={t.Code}/>
                        </span>
                        <span data-tip={t.Name} style={Object.assign({}, overflowStyle, {marginLeft: 5, width: '63%', display: 'block', float:'left'})}>{t.Name}</span>
                        <span style={{marginLeft: 7, color: primaryColor}}>{t.Time} min</span>
                    </div>
                        :
                        <div key={index}></div>
                })
            }
        </div>
        <div className="w-2/6 md:w-1/12 hidden md:block">
            <a href={url} target="_blank" rel="noreferrer">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </svg>
            </a>
        </div>
    </div>
    </>
};

export default ParadaRow;
