import React from "react";
import Layout from "./layouts/layout";
import RateCard from "./blocks/rateCard";
import es from "../i18n/es";
import en from "../i18n/en";
import Subheader from "./blocks/subheader";
import Footer from "./blocks/footer";

function Tarifas(props) {
  let textos = es;
  let locale = 'es';
  if (props.lang === 'en') { textos = en; locale = 'en'; }
  let tarifas = null;
  if (props.data && props.data.Rates) {
    tarifas = props.data.Rates;
  }
  const textColor = props.data.Config.Primary_color || 'black';

  return (
      <Layout data={props.data} withMarginTop={true} lang={props.lang}>
        {
          tarifas &&
          <div className="text-center mainViewContent">

            <Subheader text={textos.Rates} bgColor={props.data.Config.Secondary_color} />

            <div className="sm:hidden">
              {
                tarifas.map((tarifa, index) => {
                      return <RateCard key={index}
                                       mainColor={props.data.Config.Primary_color}
                                       data={tarifa}
                                       lang={props.lang}/>;
                    }
                )
              }
            </div>

            <div className="hidden sm:block">
              <div className="flex justify-center space-x-4 text-center text-blue font-bold border-b p-2">
                <div className="w-1/6" style={{color:textColor}}>{textos.Name}</div>
                <div className="w-2/6" style={{color:textColor}}>{textos.Description}</div>
                <div className="w-1/6" style={{color:textColor}}>{textos.Price}</div>
              </div>
              {
                tarifas.map((tarifa, index) => {
                  return (
                      <div
                          key={index}
                          className="flex justify-center space-x-4 text-center text-blue p-2 border-b"
                      >
                        <div className="w-1/6" style={{color:textColor}}>{tarifa.Name[locale]}</div>
                        <div className="w-2/6" style={{color:textColor}}>{tarifa.Description[locale]}</div>
                        <div className="w-1/6" style={{color:textColor}}>
                          {tarifa.Value}
                          {tarifa.Currency}
                        </div>
                      </div>
                  );
                }
              )}
            </div>
          </div>
        }

        <Footer data={props.data} />

    </Layout>
  );
}

export default Tarifas;
