import React from "react";
import Layout from "./layouts/layout";
import NoticiaCard from "./blocks/noticiaCard";
import Subheader from "./blocks/subheader";
import es from "../i18n/es";
import en from "../i18n/en";
import Footer from "./blocks/footer";

const MAX_NEWS = 10;

let isValid = function (obj) {
  return obj && obj.Image &&
      obj.Title && !Array.isArray(obj.Title) && typeof obj.Title === 'object' &&
      obj.Description && !Array.isArray(obj.Description) && typeof obj.Description === 'object';
}

function Noticias(props) {
  let textos = es;
  if (props.lang === 'en') { textos = en; }
  let noticias = null;
  if (props.data && props.data.News) {
    noticias = props.data.News;
  }
  const textColor = props.data.Config.Primary_color || 'black';
  if (noticias) {
    noticias = noticias.filter((obj) => isValid(obj));
    noticias = noticias.slice(0, MAX_NEWS);
  }
  return (
      <Layout data={props.data} withMarginTop={true} lang={props.lang}>
        {
          noticias &&
          <div className="text-center mainViewContent">

            <Subheader text={textos.News} bgColor={props.data.Config.Secondary_color} />

            {
              noticias.length === 0 &&
              <div style={{color:textColor}}>{textos.NoNews}</div>
            }

            {
              noticias.map((noticia, index) =>
                <NoticiaCard key={index}
                             mainColor={props.data.Config.Primary_color}
                             data={noticia}
                             lang={props.lang} />
              )
            }
          </div>
        }

        <Footer data={props.data} />

      </Layout>
  );
}

export default Noticias;
