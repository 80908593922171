import React from "react";

function LineCircle(props) {
    return (
        <span className={"lineBoxMini"}
              style={{
                  backgroundColor: props.color,
                  justifyContent: 'center',
                  textAlign: 'center',
              }}>
            { props.code && props.code.substring(0, 3).toUpperCase() }
        </span>
  );
}

export default LineCircle;
