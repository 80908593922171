import React from "react";
import Topbar from "../blocks/topbar";

const Layout = ({data, withMarginTop, lang, children}) => {
  return (
      <div className="flex w-screen h-screen">
          <div className="w-screen absolute top-0">
              <Topbar data={data} lang={lang}/>
          </div>
          <div className="w-screen mainViewContainer" style={{ marginTop:withMarginTop ? 80 : 0 }}>
              {children}
          </div>
      </div>
  );
};

export default Layout;
