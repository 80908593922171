
const ParadaHeader = props => {
    let textos = props.textos;
    return <div className="flex justify-center space-x-4 text-left md:text-center
                           text-sm md:text-base text-blue font-bold border-b p-2">
        <div className="w-1/6 md:w-1/12">{textos.Stop}</div>
        <div className="w-5/6 md:w-2/12 text-left">{textos.Name}</div>
        <div className="md:w-4/12 text-left hidden md:block">{textos.Times_table}</div>
        <div className="md:w-4/12 text-left hidden md:block">{textos.Next_arrivals}</div>
        <div className="w-2/6 md:w-1/12 flex-row content-end hidden md:block">{textos.Location}</div>
    </div>
};

export default ParadaHeader;
