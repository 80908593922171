import React from "react";
import es from "../i18n/es";
import en from "../i18n/en";

function Page404(props) {
    let textos = es;
    if (props.lang === 'en') { textos = en; }
    return (
        <div className="containerFullScreenCentered" style={{backgroundColor:props.data.Config.Primary_color || 'black'}}>
            <div className="centered">
                <img className="text-white h-12" src="/img/logo.svg" alt="Logo"
                     style={{margin:'auto'}}/>
                <div className="text-white" style={{marginTop: 20}}>{textos.error404Title}</div>
                <div className="text-white">{textos.error404Desc}</div>
                <div className="text-white" style={{marginTop: 20, fontSize: 12}}>
                    Empresa municipal de transportes de Torrox - M&aacute;laga</div>
                <div style={{
                    marginTop: 20,
                    borderColor: 'white',
                    borderWidth: 1,
                    borderRadius: 10
                }}>
                    <a style={{ color:'white', fontSize: 14 }} href={'/'}>{textos.goHome} ></a>
                </div>
            </div>
        </div>
  );
}

export default Page404;
