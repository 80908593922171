import React, { Component } from "react";
import { Map, InfoWindow, Marker, Polyline, GoogleApiWrapper } from "google-maps-react";
import configData from "../../config.json";
import es from "../../i18n/es.json";
import en from "../../i18n/en.json";
import {FaWalking} from "react-icons/fa";
import {GMAP_THEME} from '../../theme/GmapsDefault';
import ApiTorrox from "../../torroxApi";
import polyline from "@mapbox/polyline";
const busIcon = '/img/bus_map_pin.png';
const busStopIcon = '/img/bus_stop_map_pin_mini.png';
const busStopDisabledIcon = '/img/bus_stop_map_pin_gray_mini.png';

const LoadingContainer = (props) => {
    let textos = es;
    if (props.lang === 'en') {
        textos = en;
    }
    return <div>{textos.loading_map}</div>;
}

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            paradasMarkers: [],
            busesMarkers: [],
            routeWaypoints: [],
            buses: [],
            showRealtime: false
        };
        this.textos = es;
        this.numStopsActivated = -1;
        this.onMarkerClick = this.onMarkerClick.bind(this);
    }
    componentDidMount() {
        if (this.props.lang === 'en') {
            this.textos = en;
        }
        this.generatePolylines();
        this.generateStopsMarkers();
        this.getBuses();
        this.enableBusesInterval(this.props.data);
    }
    componentWillUnmount() {
        if (this.busesInterval) {
            clearInterval(this.busesInterval);
            this.busesInterval = null;
        }
        if (this.realtimeInterval) {
            clearInterval(this.realtimeInterval);
            this.realtimeInterval = null;
        }
    }
    enableBusesInterval(data) {
        if (!!this.props.onUpdateRealtime) {
            let sec = parseInt(data.Config.Update_position_interval) || 20;
            this.busesInterval = setInterval(this.getBuses.bind(this), sec*1000);
            this.realtimeInterval = setInterval(() => {
                this.setState({showRealtime: !this.state.showRealtime});
                this.props.onUpdateRealtime();
            }, 1000);
        }
    }
    getBuses() {
        if (!!this.props.onUpdateRealtime) {
            ApiTorrox.getWebLineBusesData(this.props.lineId).then((response) => {
                let resp = response.buses || [];
                setTimeout(() => {
                    this.setState({buses: resp});
                    this.generateBusesMarkers(resp);
                    this.generateStopsMarkers();
                }, 300);
            });
        }
    }
    getRouteWaypointsByOsrm(resp) {
        let coords = [];
        if (resp && resp.routes && resp.routes.length>0) {
            let route = resp.routes[0];
            let points = polyline.decode(route.geometry, 5);
            for (let i=0 ; i<points.length ; i++) {
                let p = points[i];
                coords.push({
                    lat: p[0],
                    lng: p[1]
                });
            }
        }
        return coords;
    }
    generatePolylines() {
        let res = [];
        let i = 0;
        if (this.props.waypoints) {
            for (let route of this.props.waypoints) {
                i += 1;
                let markers = this.getRouteWaypointsByOsrm(route);
                res.push(
                    <Polyline
                        key={i}
                        path={markers}
                        strokeColor={this.props.lineColor || 'black'}
                        strokeOpacity={0.7}
                        strokeWeight={3} />
                );
            }
        }
        this.setState({routeWaypoints: res});
    }
    getNumStopsActived() {
        return this.props.paradas.filter(p => p.Actived === true).length;
    }
    generateStopsMarkers() {
        let actived = this.getNumStopsActived();
        if (this.numStopsActivated !== actived) {
            let markers = this.props.paradas.map((parada, index) => {
                let isActived = this.props.paradasStatusColor ? parada.Actived : true;
                let markerIcon = isActived ? busStopIcon : busStopDisabledIcon;
                this.numStopsActivated = actived;
                return <Marker
                    key={index}
                    onClick={(props, marker) => this.onMarkerClick(props, marker, {...parada, pinType:'stop'})}
                    name={parada.Name}
                    position={{lat:parada.Latitude, lng: parada.Longitude}}
                    icon={{
                        url: markerIcon,
                        //anchor: new google.maps.Point(30,50),
                        //scaledSize: new google.maps.Size(30,50)
                    }}
                />
            });
            this.setState({paradasMarkers: markers});
        }
    }
    generateBusesMarkers(buses) {
        let markers = buses.map((bus, index) => {
            return <Marker
                key={index}
                onClick={(props, marker) => this.onMarkerClick(props, marker, {...bus, pinType:'bus'})}
                name={bus.Name}
                position={{lat:bus.Latitude, lng: bus.Longitude}}
                icon={{
                    url: busIcon
                }}
            />
        });
        this.setState({busesMarkers: markers});
    }
    generateMarkerInfoWindow(marker, selectedPlace) {
        let isBusStopPin = selectedPlace.pinType === 'stop';
        let isBusPin = selectedPlace.pinType === 'bus';
        let title = isBusPin ? selectedPlace.Name : this.textos.Stop_name.replace('%{name}', selectedPlace.Name);
        let desc = isBusPin ? this.textos.Next_stop_in.replace('%{min}', selectedPlace.Arrival) : null;
        let titleColor = this.props.data ? this.props.data.Config.Primary_color : 'rgb(38, 171, 225)';
        let stopDetails = null;
        let stopPanelDetails = null;
        if (this.props.data && this.props.data.stops) {
            stopDetails = this.props.data.stops.find((stop) => stop.Id === parseInt(selectedPlace.Id, 10));
            stopPanelDetails = stopDetails ? stopDetails.Panel : null;
        }
        return <InfoWindow marker={this.state.activeMarker}
                            onClose={this.onInfoWindowClose}
                            visible={this.state.showingInfoWindow}>
            <div style={{textAlign: 'left'}}>
                <div className='markerInfoTitle' style={{color:titleColor}}>{title}</div>
                { desc && <div className='markerInfoDesc' style={{color:titleColor}}>{desc}</div> }
                {
                    <div className='markerInfoPanel'>
                        {
                            !!stopPanelDetails && stopPanelDetails.length>0 &&
                            stopPanelDetails.map((panelInfoLine, index) => {
                                if (panelInfoLine.Time && (
                                    !this.props.lineCode || this.props.lineCode === panelInfoLine.Code
                                ))  {
                                    return <div key={index} style={{marginBottom: 3}}>
                                        <span className='markerInfoPanelCircle'
                                            style={{
                                                backgroundColor:panelInfoLine.Color || 'black',
                                            }}>{panelInfoLine.Code}
                                        </span>
                                        <span className="hidden md:inline" 
                                            style={{
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis', 
                                                whiteSpace: 'nowrap',
                                                maxWidth: '70%',
                                            }}>&nbsp;{panelInfoLine.Name}&nbsp;</span>
                                        <span className="inline-block md:hidden" 
                                            style={{
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis', 
                                                whiteSpace: 'nowrap',
                                                maxWidth: '77%',
                                                position: 'relative',
                                                top: 5
                                            }}>&nbsp;{panelInfoLine.Name}&nbsp;</span>
                                        <span className="hidden md:inline-flex" style={{marginLeft:10}}>{panelInfoLine.Time} min</span>
                                        <span className="md:hidden">{panelInfoLine.Time}"</span>
                                    </div>
                                }
                                return <></>;
                            })
                        }
                    </div>
                }
                {
                    isBusStopPin &&
                    <div className='markerInfoLink'>
                        <a className='markerInfoDesc'
                            style={{color:titleColor}}
                            target={'_blank'}
                            rel="noreferrer"
                            href={
                                "https://www.google.com/maps/search/?api=1&query=" +
                                selectedPlace.Latitude +
                                "%2C" +
                                selectedPlace.Longitude
                            }>
                            <span style={{display: 'inline-block', marginTop:5}}><FaWalking size={18}/></span>
                            {this.textos.go_stop}
                        </a>
                        <div></div>
                    </div>
                }
            </div>
        </InfoWindow>;
    }
    onMarkerClick = (props, marker, data) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: data,
            showingInfoWindow: true
        });
    }
    onInfoWindowClose = () => {
        this.setState({
            activeMarker: null,
            showingInfoWindow: false
        });
    }
    onMapClicked = () => {
        if (this.state.showingInfoWindow)
            this.setState({
                activeMarker: null,
                showingInfoWindow: false
            });
    };
    _mapLoaded(mapProps, map) {
        map.setOptions({
            styles: GMAP_THEME,
            //disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: false,
            //scaleControl: false,
            streetViewControl: false,
            //rotateControl: false,
            //fullscreenControl: true
        })
    }
    render() {
        if (!this.props.loaded) return <div>Loading...</div>;
        return (
            <Map className="map"
                 google={this.props.google}
                 onClick={this.onMapClicked}
                 onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
                 initialCenter={configData.default_position}
                 style={{position: "relative", height: 'calc(100vh - 145px)', width: '100%' }}
                 zoom={this.props.zoom}
            >
                { this.state.paradasMarkers }
                { this.state.busesMarkers }
                { this.state.routeWaypoints }
                { this.generateMarkerInfoWindow(this.state.activeMarker, this.state.selectedPlace) }
            </Map>
        );
    }
}
export default GoogleApiWrapper(
(props) => ({
    apiKey: configData.gmap_api_key,
    LoadingContainer: LoadingContainer,
    //version: "3.38",
    language: props.lang === 'es' ? 'es_ES' : 'en_US',
}
))(MapContainer);

