import axios from "axios";
import configData from "./config.json";

const STORE_DATA_KEY = "appData";

const ApiTorrox = {
  getSessionData() {
    return window.localStorage.getItem(STORE_DATA_KEY);
  },
  _processResponse(response) {
    let resp = response.data;
    if (resp) {
      return resp;
    }
    return null;
  },
  getRouteFromBusStops(busStops) {
    let numBusStops = busStops.length;
    let routeSteps = '';
    for (let i = 0; i < numBusStops - 1; i++) {
      let current = busStops[i];
      let next = busStops[i + 1];
      let lat = parseFloat(current.Latitude) || configData.default_position.lat;
      let long = parseFloat(current.Longitude) || configData.default_position.lng;
      let nextLat = parseFloat(next.Latitude) || configData.default_position.lat;
      let nextLong = parseFloat(next.Longitude) || configData.default_position.lng;
      let origin = long + "," + lat;
      let dest = nextLong + "," + nextLat;
      routeSteps += i===0 ? origin + ';' + dest : ';' + dest;
    }
    return new Promise((resolve, reject) => {
      axios.get(configData.IURBAN_CAR_ROUTES_URL + '/route/v1/driving/' + routeSteps + '?steps=false&overview=full').then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  },
  getWebNewsData(id) {
    return new Promise((resolve, reject) => {
      axios.get(configData.BASE_URL + '/v1/web/newsData/' + id).then((response) => {
        resolve(ApiTorrox._processResponse(response));
      }).catch(err => {
        reject(err);
      });
    });
  },
  getWebLineBusesData(lineId) {
    return new Promise((resolve, reject) => {
      axios.get(configData.BASE_URL + '/v1/web/buses/' + lineId).then((response) => {
        resolve(ApiTorrox._processResponse(response));
      }).catch(err => {
        reject(err);
      });
    });
  },
  getWebData() {
    return new Promise((resolve, reject) => {
      axios.get(configData.BASE_URL + '/v1/web/applicationDataWeb').then((response) => {
        let resp = ApiTorrox._processResponse(response);
        if (resp) {
          window.localStorage.setItem(STORE_DATA_KEY, JSON.stringify(resp));
        }
        resolve(resp);
      }).catch(err => {
        reject(err);
      });
    });
  }
}
export default ApiTorrox;
