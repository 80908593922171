import React from "react";
import { Redirect } from "wouter";
import Layout from "./layouts/layout";
import es from "../i18n/es";
import en from "../i18n/en";
import configData from "../config.json";
import Footer from "./blocks/footer";
import ParadaRow from './blocks/paradaRow';
import ParadaHeader from "./blocks/paradaHeader";
import ApiTorrox from "../torroxApi";
import MapContainer from "./blocks/mapContainer";
import SubheaderWithMenu from "./blocks/subheaderWithMenu";

export const REFRESH_INTERVAL = 15 * 1000;
export const MAX_REFRESH = 40;

class Linea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showActivity: false,
            ready: false,
            routeWaypoints: [],
            showMap: true,
            showRealtime: true,
            linea: null,
            lineaParadas: null,
            paradas: null,
            mapProps: {
                lat: configData.default_position.lat,
                lng: configData.default_position.lng
            },
            zoom: 13,//configData.default_zoom,
            buses: [],
            markerInfo: null
        };
        this.textos = es;
        this.lineId = props.id;
        this.refreshIterations = 0;
        this.refreshInterval = null;
        this.internalRef = React.createRef();
        this.parseTime = this.parseTime.bind(this);
        this.getBusStopPanelInfo = this.getBusStopPanelInfo.bind(this);
        this.toggleMapByLineId= this.toggleMapByLineId.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.isBefore = this.isBefore.bind(this);
        this.onUpdateRealtime = this.onUpdateRealtime.bind(this);
    }
    componentDidMount() {
        if (this.props.lang === 'en') {
            this.textos = en;
        }
        if (this.props.data && this.props.data.Lines) {
            this.setupView(this.props.data, this.props.id);
            this.refreshInterval = setInterval(this.refreshData.bind(this), REFRESH_INTERVAL);
        }
    }
    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    }
    onUpdateRealtime() {
        this.setState({showRealtime: !this.state.showRealtime});
    }
    setupView(data, lineId) {
        const result = data.Lines.find(
            (line) => line.Id === parseInt(lineId, 10)
        );
        let paradasData = data.stops || [];
        /*
                    paradasData = [
                        {
                            Id: 6,
                            Code: "P101",
                            Name: "De la Rosa",
                            Latitude: "36.736556",
                            Longitude: "-3.983554",
                            Direction: "C/ de la rosa",
                            Times: ["8:00", "8:30", "9:00", "10:00"],
                            Links: [ {LineId: 1}, {LineId: 2}, {LineId: 3} ],
                            Panel: [
                                {
                                    "Id": 3,
                                    "Code": "L1",
                                    "Color": "#000000",
                                    "Name": "LÍNEA 1",
                                    "Time": "+120"
                                },
                                {
                                    "Id": 4,
                                    "Code": "L2",
                                    "Color": "#ff0000",
                                    "Name": "LÍNEA 2",
                                    "Time": "3"
                                }
                            ]
                        }
                    ];
        */
        this.setState({data: data || null});
        this.setState({paradas: paradasData});
        if (result) {
            result.Stops.map(p => p.Panel = this.getBusStopPanelInfo(paradasData, p.Id));
            this.setState({linea: result});
            this.setState({lineaParadas: result.Stops});
            this.generateBusLineRouteByOsrm(result);
        }
    }
    refreshData() {
        this.refreshIterations += 1;
        if (this.refreshInterval === null) {
            this.refreshInterval = setInterval(this.refreshData.bind(this), REFRESH_INTERVAL);
        }
        if (this.refreshIterations === MAX_REFRESH) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
            this.refreshIterations = 0;
        }
        this.setState({showActivity: true});
        ApiTorrox.getWebData().then((response) => {
            setTimeout(() => {
                this.setupView(response, this.props.id);
                this.setState({showActivity: false});
            }, 500);
        });
    }
    isBefore(timing) {
        let now = new Date();
        let hourC = now.getHours();
        let minC = now.getMinutes();
        if (timing) {
            let arr = timing.split(':');
            let hourT = parseInt(arr[0]);
            let minT = parseInt(arr[1]);
            return hourT > hourC || (hourT===hourC && minT >= minC);
        }
        return true;
    }
    setMarkerInfoWindow(data) {
        setTimeout(() => {
            this.setState({markerInfo: data ? data : null});
        }, 100);
    }
    generateBusLineRouteByOsrm(busLineData) {
        if ( !busLineData || !busLineData.Stops ) { return; }
        let busStopList = busLineData.Stops;
        ApiTorrox.getRouteFromBusStops(busStopList).then((response) => {
            this.setState({routeWaypoints: [response]});
            this.setState({ready: true});
        }).catch(err => {
          this.setState({routeWaypoints: []});
          this.setState({ready: false});
      });
    }
    toggleMapByLineId(lineId) {
        this.setState({showMap: !this.state.showMap});
    }
    parseTime(time) {
        let t = time.split(':');
        return t[0] + ':' + t[1];
    }
    getBusStopPanelInfo(paradas, stopId) {
        let result = [];
        if (paradas) {
            result = paradas.find(
                (stop) => stop.Id === parseInt(stopId, 10)
            );
        }
        return result ? result.Panel : [];
    }
    render() {
        let isBefore = this.isBefore;
        let parseTime = this.parseTime;
        let primaryColor = this.state.data ? this.state.data.Config.Primary_color : 'black';
        let linea = this.state.linea;
        let lineaParadas = this.state.lineaParadas;
        let textos = this.textos;
        return (
            <div ref={this.internalRef}>
                {
                    this.state.data &&
                    <Layout data={this.state.data} withMarginTop={true} lang={this.props.lang}>
                        {
                            linea && lineaParadas ?
                            <div className="text-center mainViewContent">

                            <SubheaderWithMenu textos={textos}
                                                linea={linea} 
                                                config={this.state.data ? this.state.data.Config : null} 
                                                showMap={this.state.showMap} 
                                                showActivity={this.state.showActivity} 
                                                refreshData={this.refreshData}
                                                toggleMapByLineId={this.toggleMapByLineId} />

                                {
                                    this.state.showMap && this.state.ready ?
                                        <div style={{position:'relative'}}>
                                            <div className='bg-gray realtimeBanner'
                                                 style={{backgroundColor: this.state.data.Config.Primary_color}}>
                                                {this.textos.Realtime_data}
                                                <span className={'realtimeBannerDot ' + (!this.state.showRealtime ? 'realtimeBannerDotHidden' : '')}></span>
                                            </div>

                                            <MapContainer ref={this.customMapRef}
                                                          data={this.state.data}
                                                          lang={this.props.lang}
                                                          lineId={this.lineId}
                                                          lineCode={linea.Code}
                                                          lineColor={this.state.linea.Color}
                                                          paradas={lineaParadas}
                                                          paradasStatusColor={true}
                                                          zoom={this.state.zoom}
                                                          waypoints={this.state.routeWaypoints}
                                                          onUpdateRealtime={this.onUpdateRealtime}
                                            />
                                        </div>
                                        :
                                        <div style={{paddingTop:0}}>
                                            <ParadaHeader textos={textos} />
                                            <div>
                                                {
                                                    lineaParadas.map(function (parada, index) {
                                                        return (
                                                            <ParadaRow key={index}
                                                                       linea={linea}
                                                                       data={parada}
                                                                       primaryColor={primaryColor}
                                                                       textos={textos}
                                                                       isBefore={isBefore}
                                                                       parseTime={parseTime} />
                                                        );
                                                    })}
                                            </div>
                                        </div>

                                }
                            </div>
                                :
                                <Redirect to={"/404"} />
                        }

                        <Footer data={this.state.data} />

                    </Layout>
                }
            </div>
        )
    }
}

export default Linea;
