import React from "react";

function Footer(props) {
    let now = new Date();
    return (
        <div className="text-center fixed w-screen bottom-0"
           style={{backgroundColor:'black', fontSize: 10, color:'#ccc'}}>
            Copyright { now.getFullYear() } - Ayuntamiento de Torrox
      </div>
  );
}

export default Footer;
