import React, {useState} from "react";
import es from "../../i18n/es";
import en from "../../i18n/en";
import configData from "../../config.json";

function Topbar(props) {
    const [toggleMenu, setToggleMenu] = useState(false);
    let textos = es;
    let locale = 'es';
    let params = '';
    if (props.lang === 'en') {
        textos = en;
        locale = 'en';
        params = '?l=en';
    }
    const pathname = window.location.pathname;
    let lineas = null;
    if (props.data && props.data.Lines) {
        lineas = props.data.Lines;
    }
    const langs = [
        {label: "Español", code: "es"},
        {label: "English", code: "en"}
    ];
    let urlPrefix = configData.url_prefix==='' || configData.url_prefix==='/' ? '' : configData.url_prefix;

    let linkSimpleClassStyle = 'py-2 px-4 hidden md:block';
    let linkSimpleClassStyleForPhones = 'block md:hidden py-2 text-white px-2';
    let menuSimpleClassStyleForPhones = 'block md:hidden py-2 text-indigo-400 px-2';
    let linkMenuSimpleClassStyleForPhones = 'block md:hidden py-2 text-white px-5';
    let linkMenuClassStyle = 'group inline-block relative hidden md:block';
    return (
        <div className="bg-header text-white w-screen relative topbarHeader"
           style={{backgroundColor:props.data.Config.Primary_color || 'black'}}>
          <div className="container mx-auto">
              <div className="p-4 flex justify-between items-center">
                  <a href={'/' + params}>
                      <img className="text-white h-12" src="/img/logo.svg" alt="Logo" />
                      {
                          configData.BASE_URL.indexOf('preproduccion') >= 0 &&
                          <div style={{position: 'absolute', top: 58, color: 'red'}}>PREPRODUCTION</div>
                      }
                  </a>

              <button className="inline-block md:hidden w-8 h-8 bg-gray-200 text-gray-600 p-1"
                      onClick={() => {setToggleMenu(!toggleMenu)} }>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                            clipRule="evenodd"></path>
                  </svg>
              </button>
              <nav className={"absolute text-xs md:text-lg md:relative top-16 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-semibold " +
              "w-full md:w-auto bg-darkblue shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent p-6 pt-5 md:p-0" + (!toggleMenu ? ' hidden' : '')} >

                  <a href={urlPrefix + "/" + params} className={linkSimpleClassStyleForPhones}>{textos.Home}</a>
                  <div className={menuSimpleClassStyleForPhones}>{textos.Lines}</div>
                  {
                      lineas && lineas.map(function (linea, index) {
                          const url_linea = urlPrefix + "/linea/" + linea.Id + params;
                          return (
                              <a key={index}
                                 style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                 }}
                                 href={url_linea}
                                 className={linkMenuSimpleClassStyleForPhones}>{linea.Code} - {linea.Name}</a>
                          );
                      })
                  }
                  <a href={urlPrefix + "/paradas" + params} className={linkSimpleClassStyleForPhones}>{textos.Stops}</a>
                  <a href={urlPrefix + "/tarifas" + params} className={linkSimpleClassStyleForPhones}>{textos.Rates}</a>
                  <a href={urlPrefix + "/noticias" + params} className={linkSimpleClassStyleForPhones}>{textos.News}</a>
                  <div className={menuSimpleClassStyleForPhones}>{textos.Languages}</div>
                  {
                      langs && langs.map(function (lang, index) {
                          const url_lang = urlPrefix + pathname + "?l=" + lang.code;
                          return (
                              <a key={index}
                                 href={url_lang}
                                 className={linkMenuSimpleClassStyleForPhones}>{lang.label}</a>
                          );
                      })
                  }


                      {
                          lineas &&
                          <div className={linkMenuClassStyle}>
                              <div className="text-white py-2 px-4 inline-flex items-center">
                                  <span className="mr-1">{textos.Lines}</span>
                                  <svg
                                      className="fill-current h-4 w-4"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                  >
                                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                  </svg>
                              </div>
                              <ul className="absolute hidden text-black md:text-white pt-4 group-hover:block z-30 w-96">
                                  {lineas.map(function (linea, index) {
                                      const url_linea = urlPrefix + "/linea/" + linea.Id + params;
                                      return (
                                          <li className="" key={index}>
                                              <a className="bg-header py-2 px-4 block whitespace-no-wrap submenuItem"
                                                 style={{backgroundColor:props.data.Config.Primary_color || 'black'}}
                                                 href={url_linea}>
                                                  {linea.Code} - {linea.Name}
                                              </a>
                                          </li>
                                      );
                                  })}
                              </ul>
                          </div>
                      }

                  <a href={urlPrefix + "/paradas" + params} className={linkSimpleClassStyle}>{textos.Stops}</a>
                  <a href={urlPrefix + "/tarifas" + params} className={linkSimpleClassStyle}>{textos.Rates}</a>
                  <a href={urlPrefix + "/noticias" + params} className={linkSimpleClassStyle}>{textos.News}</a>

                      <div className={linkMenuClassStyle}>
                          <div className="text-white py-2 px-4 inline-flex items-center">
                              <span className="mr-1">{locale.toUpperCase()}</span>
                              <svg
                                  className="fill-current h-4 w-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                              >
                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                          </div>
                          <ul className="absolute hidden text-white pt-4 group-hover:block z-30 w-32 text-center" style={{right:0}}>
                              {
                                  langs.map((lang, index) => {
                                      return <li key={index}>
                                          <a className="bg-header py-2 px-4 block whitespace-no-wrap submenuItem"
                                             style={{backgroundColor:props.data.Config.Primary_color || 'black'}}
                                             href={urlPrefix + pathname + "?l=" + lang.code}>
                                              {lang.label}
                                          </a>
                                      </li>
                                  })
                              }
                          </ul>
                      </div>

                  </nav>
              </div>
          </div>
      </div>
  );
}

export default Topbar;
