import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Layout from "./layouts/layout";
import es from "../i18n/es";
import en from "../i18n/en";
import Footer from "./blocks/footer";

function Home(props) {
    let textos = es;
    if (props.lang === 'en') { textos = en; }

    return (
      <>
          props.data &&
          <Layout data={props.data} lang={props.lang}>
              <div className="w-screen h-screen" style={{zIndex:90}}>
                  <div className="flex">
                      <div className="homeTitle">
                          <p>{textos.HomeTitle}</p>
                          <div className="homeDesc">{textos.HomeDesc}</div>
                      </div>
                  </div>

                  <div className="bgImage sm:hidden" style={{backgroundImage:"url('/img/slider1-xs.jpg')"}}></div>

                  <Carousel
                      className="hidden sm:block"
                      autoPlay={true}
                      interval={5000}
                      swipeable={false}
                      showIndicators={false}
                      showThumbs={false}
                      infiniteLoop={true}
                      showStatus={false}
                  >
                      <div className="w-screen h-screen">
                          <img src="/img/slider1.jpg" alt="Slider 1"/>
                      </div>
                      <div className="w-screen h-screen">
                          <img src="/img/slider2.jpg" alt="Slider 1"/>
                      </div>
                  </Carousel>
              </div>

              <Footer data={props.data} />

          </Layout>
      </>
  );
}

export default Home;
