import React  from "react";
import { Redirect } from "wouter";
import Layout from "./layouts/layout";
import NoticiaCard from "./blocks/noticiaCard";
import es from "../i18n/es";
import en from "../i18n/en";
import Footer from "./blocks/footer";
import ApiTorrox from "../torroxApi";

class Noticia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noticia: null
    };
    this.nId = props.id;
    this.textos = es;
  }
  componentDidMount() {
    if (this.props.lang === 'en') { this.textos = en; }
    if (this.nId) {
      ApiTorrox.getWebNewsData(this.nId).then((response) => {
        this.setState({noticia: response});
      });
    }
  }
  render() {
    return (
        <Layout data={this.props.data} withMarginTop={true} lang={this.props.lang}>
          {
            !this.state.noticia &&
            <div className="text-center">{this.textos.NoNewsDetails}</div>
          }

          {
            this.state.noticia ?
            <div className="text-center">
              <div className="bg-darkblue text-white p-2 mb-5 w-screen topbarSubHeader">{this.textos.News}</div>
              <NoticiaCard key={0}
                           mainColor={this.props.data.Config.Primary_color}
                           data={this.state.noticia}
                           linkDisabled={true}/>
            </div>
                :
                <Redirect to={"/404"} />
          }

          <Footer data={this.props.data} />

        </Layout>
    )
  }
}

export default Noticia;
