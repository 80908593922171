import React from "react";
import Layout from "./layouts/layout";
import en from "../i18n/en";
import Linea, {REFRESH_INTERVAL} from "./linea";
import ParadaRow from './blocks/paradaRow';
import Footer from "./blocks/footer";
import ParadaHeader from "./blocks/paradaHeader";
import MapContainer from "./blocks/mapContainer";
import SubheaderWithMenu from "./blocks/subheaderWithMenu";

class Paradas extends Linea {
    componentDidMount() {
        if (this.props.lang === 'en') {
            this.textos = en;
        }
        setTimeout(() => {
            if (this.props.data && this.props.data.Lines) {
                this.setState({paradas: this.props.data.stops});
                this.realtimeInterval = setInterval(() => {
                    this.setState({showRealtime: !this.state.showRealtime});
                }, 1000);
                this.refreshInterval = setInterval(this.refreshData.bind(this), REFRESH_INTERVAL);
            }
            this.setState({data: this.props.data || null});
            this.setState({ready: true});
            this.setState({showMap: false});
        }, 100);
      }
    render() {
        let isBefore = this.isBefore;
        let parseTime = this.parseTime;
        let primaryColor = this.props.data.Config.Primary_color;
        let textos = this.textos;
        let paradas = this.state.paradas;
        let lines = this.props.data.Lines;
        return (
            <div ref={this.internalRef}>
                <Layout data={this.props.data} withMarginTop={true} lang={this.props.lang}>
                    {
                        paradas &&
                        <div className="text-center mainViewContent">

                            <SubheaderWithMenu textos={textos}
                                                menuLabel={textos.Stops}
                                                config={this.state.data ? this.state.data.Config : null} 
                                                showMap={this.state.showMap}
                                                showActivity={this.state.showActivity} 
                                                refreshData={this.refreshData}
                                                toggleMapByLineId={this.toggleMapByLineId} />

                            {
                                this.state.showMap && this.state.ready ?
                                    <>
                                        <div className='bg-gray realtimeBanner'
                                             style={{backgroundColor: this.props.data.Config.Primary_color}}>
                                            {this.textos.Realtime_data}
                                            <span className={'realtimeBannerDot ' + (!this.state.showRealtime ? 'realtimeBannerDotHidden' : '')}></span>
                                        </div>
                                        {
                                            this.state.markerInfo &&
                                            this.renderMarkerInforWindow()
                                        }

                                        <MapContainer ref={this.customMapRef}
                                                      data={this.state.data}
                                                      lang={this.props.lang}
                                                      paradas={paradas}
                                                      paradasStatusColor={false}
                                                      zoom={this.state.zoom}
                                        />
                                    </>
                                    :
                                    <div style={{paddingTop:0}}>
                                        <ParadaHeader textos={textos} />
                                        <div>
                                            {
                                                paradas.map(function (parada, index) {
                                                    return (
                                                        <ParadaRow key={index}
                                                                   data={parada}
                                                                   busLines={lines}
                                                                   primaryColor={primaryColor}
                                                                   textos={textos}
                                                                   isBefore={isBefore}
                                                                   parseTime={parseTime} />
                                                    );
                                                })}
                                        </div>
                                    </div>
                            }
                        </div>
                    }

                    <Footer data={this.props.data} />

                </Layout>
            </div>
        )
    }
}

export default Paradas;
