import React from "react";
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import { Route, Switch } from "wouter";
import Home from "./components/home";
import Page404 from "./components/page404";
import Linea from "./components/linea";
import Tarifas from "./components/tarifas";
import Noticias from "./components/noticias";
import Noticia from "./components/noticia";
import Paradas from "./components/paradas";
import ApiTorrox from "./torroxApi";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const queryParams = new URLSearchParams(window.location.search);
let locale = queryParams.get('l');
if (locale !== 'en' || locale === 'es') {
  locale = 'es';
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showSpinner: true,
      spinnerColor: '#000'
    };
  }
  componentDidMount() {
    setTimeout(() => {
      ApiTorrox.getWebData().then((resp) => {
        setTimeout(() => {
          if (resp) {
            this.setState({data: resp});
          }
        }, 1000);
      });
    }, 100);
  }
  render() {
    return (
        <>
          {
            this.state.data ?
            <>
              <Switch>
                <Route path="/">
                  <Home data={this.state.data} lang={locale}/>
                </Route>
                <Route path="/linea/:id">{(params) =>
                    <Linea id={params.id} data={this.state.data} lang={locale} />}
                </Route>
                <Route path="/paradas">
                  <Paradas data={this.state.data} lang={locale} />
                </Route>
                <Route path="/tarifas">
                  <Tarifas data={this.state.data} lang={locale} />
                </Route>
                <Route path="/noticias">
                  <Noticias data={this.state.data} lang={locale} />
                </Route>
                <Route path="/noticia/:id">
                  {(params) =>
                      <Noticia id={params.id} data={this.state.data} lang={locale} />
                  }
                </Route>
                <Route>
                  <Page404 lang={locale} data={this.state.data} />
                </Route>

              </Switch>
            </>
                :
                <div className="w-screen h-screen flex justify-center items-center sweet-loading">
                  <PulseLoader color={this.state.spinnerColor}
                               loading={this.state.showSpinner}
                               css={override}
                               size={10} />
                </div>
          }
        </>
    );
  }
}

export default App;
