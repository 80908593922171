import React from "react";

const Subheader = ({text, bgColor, className}) => {
  return (
    <div className={"bg-darkblue text-white p-2 mb-5 w-screen topbarSubHeader " + className}
         style={{backgroundColor:bgColor || 'black'}}>
      {text}
    </div>
  );
};

export default Subheader;
