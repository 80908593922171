import React from "react";

function RateCard(props) {
    let locale = 'es';
    if (props.lang === 'en') {
        locale = 'en';
    }
    let tarifa = null;
    if (props.data && props.data) {
        tarifa = props.data;
    }
    return (
        <>
            {
                tarifa &&
                <div className="rateCard" style={{borderColor: '#ddd', borderRadius: 5}}>
                    <div style={{fontWeight: 'bold', color: props.mainColor || 'black'}}>
                        {tarifa.Name[locale]}</div>
                    <div style={{fontSize: 14, color: props.mainColor || 'black'}}>
                        {tarifa.Value}
                        {tarifa.Currency}
                    </div>
                    <div style={{fontSize: 14, color: props.mainColor || 'black'}}>
                        {tarifa.Description[locale]}</div>
                </div>
            }
        </>
    );
}

export default RateCard;
