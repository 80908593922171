import React from "react";
import Spinner from "react-activity/dist/Spinner";
import "react-activity/dist/Spinner.css";
import { FaMapMarkedAlt, FaListUl } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { IconContext } from "react-icons";
import LineCircle from "./lineCircle";

const overflowStyle = {
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  whiteSpace: 'nowrap'
};

const SubheaderWithMenu = ({textos, menuLabel, linea, config, showMap, showActivity, refreshData, toggleMapByLineId}) => {
  let primaryColor = config ? config.Primary_color : 'black';
  let secondaryColor = config ? config.Secondary_color : 'black';
  return (
    <>
          <div className="flex md:justify-center space-x-4 text-center text-white
                  font-bold border-b p-2 mb-5 w-screen topbarSubHeader"
            style={{backgroundColor:secondaryColor, height:40}}>
          <div className="hidden md:w-1/12">&nbsp;</div>
          <div className="md:w-8/12 text-left md:text-center" style={{margin:0}}>
              <span style={{display: 'inline-flex'}}>
                  {
                    linea ?
                    <>
                      <span style={{position:'relative', top: -4, marginRight: 3}}>
                          <LineCircle color={linea.Color} code={linea.Code}/>
                      </span>
                      
                      <span className="hidden md:inline" 
                            style={Object.assign({}, overflowStyle, {maxWidth:300})}> - {linea.Name}</span>

                      <span className="md:hidden" 
                            style={Object.assign({}, overflowStyle, {position: 'relative', top: 3, fontSize: 12, maxWidth:200})}> - {linea.Name}</span>
                    </> :
                    <div>{menuLabel}</div>
                  }
              </span>

              <span className={'hidden md:block absolute'} style={{top: 5, right: 40}}>
                  <button className="px-2 py-1.5 text-white text-xs bg-blue rounded-full
              hover:bg-bluelight hover:text-white ml-3"
                          style={{top: -2}}
                          onClick={() => toggleMapByLineId(linea ? linea.Id : null)}>
                      <IconContext.Provider value={{
                          size: 16,
                          style: {color:"#fff", display: 'inline', marginLeft:5, marginRight: 5},
                          className: "react-icons" }}>
                          {
                              !showMap ? <FaMapMarkedAlt/> : <FaListUl/>
                          }
                      </IconContext.Provider>
                      { showMap ? textos.Show_stops : textos.Show_map }
                  </button>
                  <button className="px-2 py-1.5 text-white text-xs bg-blue rounded-full
              hover:bg-bluelight hover:text-white ml-3"
                          style={{
                              top: -2,
                              backgroundColor: showActivity ? '#aaa' : ''
                          }}
                          disabled={showActivity}
                          onClick={() => refreshData()}>
                      <IconContext.Provider value={{
                          size: 16,
                          style: {color:"#fff", display: 'inline', marginLeft:5, marginRight: 5},
                          className: "react-icons" }}>
                          <HiRefresh />
                      </IconContext.Provider>
                      { textos.Update_data }
                  </button>

                  {
                      showActivity &&
                      <span className={'absolute'} style={{top: 8, marginLeft: 10}}>
                          <Spinner size={10} color={'white'}/>
                      </span>
                  }
              </span>

              <span className={'md:hidden'}>
                  <button className="px-2 py-1 text-white text-xs bg-blue rounded-full
                                      hover:bg-bluelight hover:text-white absolute mr-2"
                          style={{ right: 50, fontSize: 10 }}
                          onClick={() => toggleMapByLineId(linea ? linea.Id : null)}>
                      <IconContext.Provider value={{
                          size: 16,
                          style: {color:"#fff", display: 'inline', marginLeft:5, marginRight: 5},
                          className: "react-icons" }}>
                          {
                              !showMap ? <FaMapMarkedAlt/> : <FaListUl/>
                          }
                      </IconContext.Provider>
                  </button>

                  <button className="px-2 py-1 text-white text-xs bg-blue rounded-full
                                    hover:bg-bluelight hover:text-white absolute mr-2"
                          style={{
                              right: 0,
                              fontSize: 10,
                              backgroundColor: showActivity ? '#aaa' : ''
                          }}
                          disabled={showActivity}
                          onClick={() => refreshData()}>
                      <IconContext.Provider value={{
                          size: 16,
                          style: {color:"#fff", display: 'inline', marginLeft:5, marginRight: 5},
                          className: "react-icons" }}>
                          <HiRefresh />
                      </IconContext.Provider>
                  </button>

                  {
                      showActivity &&
                      <span style={{top:showMap ? 60 : 45, position: 'absolute', display: 'block'}}>
                          <Spinner size={10} color={primaryColor}/>
                      </span>
                  }
              </span>

          </div>
          <div className="hidden md:w-3/12"></div>
      </div>
    </>
  );
};

export default SubheaderWithMenu;
